@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@layer utilities {
  .collapse {
    visibility: visible;
  }
}

:root {
  --primary: theme('colors.primary');
  --primary-content: theme('colors.primary-content');
  --secondary: theme('colors.secondary');
  --secondary-content: theme('colors.secondary-content');
  --neutral: theme('colors.neutral');
  --neutral-content: theme('colors.neutral-content');
  --base-100: theme('colors.base-100');
  --base-200: theme('colors.base-200');
  --base-300: theme('colors.base-300');
  --base-content: theme('colors.base-content');
  --info: theme('colors.info');
  --info-content: theme('colors.info-content');
  --success: theme('colors.success');
  --success-content: theme('colors.success-content');
  --warning: theme('colors.warning');
  --warning-content: theme('colors.warning-content');
  --error: theme('colors.error');
  --error-content: theme('colors.error-content');
  --font-rubik: theme('fontFamily.rubik');
  background-color: var(--base-200);
  color: var(--base-content);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--base-content);
  max-width: 100vw;
  overflow-x: hidden;
  font-family: 'Rubik', sans-serif;
  font-size: 1rem;
  background-color: var(--base-200);
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

ul:not(.htmlDisplay ul):not(.ck-content ul):not(.codex-editor ul) {
  padding: 0;
  margin: 0;
  list-style: none;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

img,
video {
  max-width: 100%;
  height: auto;
}

*,
::before,
::after {
  border-width: 0;
  border-style: solid;
  border-color: currentColor;
}

button {
  background: transparent;
  padding: 0;
  cursor: pointer;
}

.smallColorPicker .react-colorful {
  width: 120px;
  height: 120px;
}

/*React Day Picked Component*/
.rdp,
.rdp select option {
  background-color: var(--base-100) !important;
  color: var(--base-content) !important;
}

.rdp-button:focus {
  background-color: var(--base-100) !important;
}

.rdp-button:hover {
  background-color: var(--base-300) !important;
  color: var(--base-100) !important;
}

.rdp-caption_label {
  background-color: var(--base-100) !important;
}

/*CK Editor Component*/
.ck {
  border: none !important;
  border-radius: 8px !important;
  word-wrap: break-word !important;
  word-break: break-all;
}

.ck-toolbar {
  background-color: var(--base-100) !important;
}

.ck-button,
.ck-splitbutton {
  color: var(--base-content) !important;
}

.ck-splitbutton_open {
  background-color: var(--base-300) !important;
  color: var(--base-100) !important;
}

.ck-splitbutton_open .ck-splitbutton__action,
.ck-splitbutton_open .ck-splitbutton__arrow {
  background-color: transparent !important;
  color: var(--base-100) !important;
}

.ck.ck-splitbutton .ck-splitbutton__arrow:not(:focus),
.ck.ck-splitbutton .ck-splitbutton__action:not(:focus) {
  background-color: transparent !important;
  color: inherit !important;
}

.ck-button:hover,
.ck-splitbutton:hover,
.ck-dropdown:hover {
  background-color: var(--base-300) !important;
  color: var(--base-100) !important;
}

.ck-on {
  color: var(--base-100) !important;
  background-color: var(--base-content) !important;
}

.ck-dropdown__panel,
.ck-dropdown__button {
  background-color: var(--base-100) !important;
}
.ck-dropdown__button {
  color: var(--base-content) !important;
}

.ck-dropdown__panel-visible ul {
  background-color: var(--base-100) !important;
}

.ck-editor__top {
  border: 1px solid var(--primary) !important;
  border-radius: 8px !important;
  margin-bottom: 4px !important;
}

.ck-editor__editable {
  background-color: var(--base-100) !important;
}

.ck-editor__main {
  border: 1px solid var(--primary) !important;
  background-color: var(--base-100);
  border-radius: 8px !important;
  min-height: 100px !important;
}

.ck-focused {
  box-shadow: none !important;
}

.ck-placeholder {
  font-family: 'Rubik';
  font-size: 0.9rem;
}

.ck-content pre {
  color: var(--text-base-content) !important;
  background-color: var(--neutral-content) !important;
  border-radius: 8px !important;
}

.ck-content a {
  @apply text-primary hover:opacity-75 transition-all duration-300 ease-in-out cursor-pointer;
}

.badge {
  @apply py-1;
}
.badge-outline {
  @apply bg-base-100;
}

.ant-input {
  font-weight: 500 !important;
  background-color: var(--base-100) !important;
  color: var(--base-content) !important;
}

.ant-input::placeholder {
  font-weight: 400 !important;
}

/* Para os componentes: ProgressBarLesson, ProgressBarLessons, ProgressBarCourse */
.ExProgress {
  margin-top: -12px !important;
  height: 10px !important;
}

.ExProgress div.ant-progress {
  height: 0px !important;
  line-height: 0px !important;
}

.ExProgress div.ant-progress-inner {
  border-radius: 0rem 0rem 0.5rem 0.5rem !important;
  margin-top: -12px !important;
}

.ExProgress div.ant-progress-outer {
  height: 0px !important;
  line-height: 0px !important;
  background-color: blue !important;
}

.NextStepsContent div.ant-collapse-content-box {
  padding: 0px !important;
}

.NextStepsHeader div.ant-collapse-header {
  padding: 0px !important;
}

/* Card container Lesson*/

.card-container-lesson > .ant-tabs-card .ant-tabs-content {
  margin-top: -16px;
}
.card-container-lesson > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 16px;
  background: var(--base-100);
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}
.card-container-lesson > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}
.card-container-lesson > .ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container-lesson > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
  font-size: 16px;
  color: var(--secondary-content);
}

/* aba selecionada */
.card-container-lesson > .ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact']
  .card-container-lesson
  > .ant-tabs-card
  .ant-tabs-tab-active {
  background: var(--base-100);
}

/* aba selecionada */
.card-container-lesson .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--primary) !important;
}

.card-container-lesson
  > .ant-tabs-card.ant-tabs-top
  > .ant-tabs-nav
  .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: transparent;
}

/* Card container Activity*/

.card-container-activity > .ant-tabs-card .ant-tabs-content {
  margin-top: -16px;
}
.card-container-activity
  > .ant-tabs-card
  .ant-tabs-content
  > .ant-tabs-tabpane {
  padding: 16px;
  padding-left: 0px;
  background: var(--base-100);
  /* border-bottom-left-radius: 12px !important; */
  border-bottom-right-radius: 12px !important;
}
.card-container-activity > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}
.card-container-activity > .ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container-activity > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
  /* border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important; */
  font-size: 16px;
  color: var(--secondary-content);
}

.card-container-activity .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  flex: none !important;
}

/* aba selecionada */
.card-container-activity > .ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact']
  .card-container-activity
  > .ant-tabs-card
  .ant-tabs-tab-active {
  background: var(--base-100);
}

/* aba selecionada */
.card-container-activity .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--primary) !important;
}

.card-container-activity
  > .ant-tabs-card.ant-tabs-top
  > .ant-tabs-nav
  .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: transparent;
}

/* .edit-activities-dropdown */
.edit-activities-dropdown > .ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 8px 0px 4px 0px;
  text-align: left;
  list-style-type: none;
  background-color: var(--base-100);
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 2px 10px 15px rgba(156, 114, 255, 0.2) !important;
}

.ant-input {
  font-weight: 400 !important;
}

.ant-card-body {
  padding: 0px !important;
}

/* select */
.astro-select
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 8px;
}

.astro-select-2
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 8px;
}

.astro-select .ant-form-item-label > label {
  font-family: 'Rubik';
  font-size: 16px;
}

.astro-select-2 .ant-form-item-label > label {
  font-family: 'Rubik';
  font-size: 16px;
  font-weight: 500;
}

.astro-select
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  cursor: auto !important;
}

.astro-select .ant-picker.ant-picker-disabled {
  cursor: auto !important;
}

.astro-select .ant-picker-input > input[disabled] {
  cursor: auto !important;
}

/* notification */
.ant-notification-notice {
  border-radius: 16px !important;
  margin: 0px !important;
}

.ant-notification-notice-description {
  margin: 0px !important;
  padding: 0px !important;
}
@media (max-width: 768px) {
  .w-md-editor-toolbar {
    display: flex;
    flex-wrap: wrap;
    height: 70px !important;
    justify-content: space-between;
  }
}

.rmdp-input {
  width: 100%;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.KlassCard div.ant-collapse-header {
  padding: 0px !important;
  height: 72px;
  display: flex !important;
  align-items: center !important;
}

.video-responsive {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.video-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.backButtonContent div.ant-skeleton-content {
  display: flex;
  justify-content: flex-end;
}

.dashboardCourseProgressDivider {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.modalAddStudentSelectArrow span.ant-select-arrow {
  width: 20px;
  position: absolute;
  top: 12px;
  right: 15px;
}

.astro-upload .ant-upload-list-item {
  height: 36px !important;
  padding-bottom: 8px;
  font-size: 16px !important;
  color: var(--error) !important;
}

.astro-upload .anticon {
  color: var(--error) !important ;
  font-size: 24px !important;
}

.astro-upload .ant-upload-list-item-info {
  background-color: var(--base-100) !important;
  width: 500px !important;
}

.astro-upload .ant-upload-list-item-card-actions-btn {
  opacity: 1 !important;
}

.activity-progress .ant-progress-inner {
  border-radius: 0;
  background-color: var(--secondary-content) !important;
}

/* Scroll Bar */

.costum-scroll::-webkit-scrollbar {
  width: 4px;
}

.costum-scroll::-webkit-scrollbar-track {
  background: transparent;
}

.costum-scroll::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 20px;
}
.costum .activity-progress .ant-progress-bg {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.daytime {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#update_teacher .ant-select-selector {
  border-radius: 8px;
}

#update_teacher .ant-select-arrow {
  width: 20px;
}

#classroom-url_klassLink {
  color: var(--dafault-gray) !important;
  font-family: var(--font-rubik) !important;
  font-weight: 500 !important;
}

#calendar-page .ant-popover-inner-content {
  padding: 0 !important;
}

.rmdp-day-picker {
  grid-gap: 20px 10px !important;
}

div.ql-toolbar {
  border: none !important;
  background-color: var(--base-100) !important;
  border-radius: 16px 16px 0px 0px !important;
}

div.ql-container {
  border: none !important;
  min-height: 112px !important;
}

div.quill {
  border: 1px solid var(--primary) !important;
  border-radius: 16px !important;
  background-color: var(--base-100) !important;
}

#studentScheduledBody .ant-collapse-header {
  justify-content: flex-end;
  display: flex;
  padding-right: 0px !important;
}

#studentScheduledBody .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
}

/* Dropdown Filter */
.scroll-dropdown::-webkit-scrollbar {
  width: 6px;
}

.scroll-dropdown::-webkit-scrollbar-track {
  background: var(--neutral-content);
  border-radius: 20px;
}

.scroll-dropdown::-webkit-scrollbar-thumb {
  background-color: var(--primary-content);
  border-radius: 20px;
}

.select {
  background-image: url('../../public/images/cheveronDown.svg');
  background-size: 20px 20px, 20px 20px;
  background-position: calc(100% - 12px) calc(1px + 50%),
    calc(100% - 16px) calc(1px + 50%);
}

#dropdown .MuiPaper-root {
  @apply py-0 overflow-hidden shadow-default   rounded-lg;
}

#popover .MuiPaper-root {
  @apply rounded-lg shadow-default   p-2;
}

.tooltip-container {
  border-color: inherit !important;
  @apply shadow-default   !important;
}

.tooltip-arrow::before {
  display: none !important;
}

.tooltip-container[data-popper-placement*='bottom'] .tooltip-arrow::after {
  border-color: transparent transparent currentColor transparent !important;
}

.tooltip-container[data-popper-placement*='top'] .tooltip-arrow::after {
  border-color: currentColor transparent transparent transparent !important;
}

.tooltip-container[data-popper-placement*='left'] .tooltip-arrow::after {
  border-color: transparent transparent transparent currentColor !important;
}

.tooltip-container[data-popper-placement*='right'] .tooltip-arrow::after {
  border-color: transparent currentColor transparent transparent !important;
}

.courseTrack article figure:after {
  @apply h-2 w-[110%] last:w-0 z-0 bg-primary ml-2 mt-[1.35rem] absolute shrink-0 grow;
  content: '';
}

.courseTrack article:nth-of-type(odd) figure:after {
  @apply rotate-[10deg];
}

.courseTrack article:nth-of-type(even) figure:after {
  @apply w-[112%] -mt-[1.15rem] -rotate-[10.7deg];
}

.courseTrack article:last-of-type figure:after {
  @apply content-none;
}

.linearProgress {
  background: #8078ff !important;
  background: linear-gradient(90deg, #8078ff 0%, #78bfff 100%) !important;
  border-radius: 100px !important;
}

.scroll-tabpanel::-webkit-scrollbar {
  height: 2px;
}

.scroll-tabpanel::-webkit-scrollbar-track {
  background: white;
  border-radius: 20px;
}

.scroll-tabpanel::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 20px;
}

.ck-powered-by-balloon {
  display: none !important;
}

input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.hex {
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.chart-label:hover {
  @apply transition-all duration-200 ease-in-out fill-secondary opacity-90 cursor-pointer scale-105;
}

.chart-label:active {
  transform: scale(0.98);
}
